import React, { useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import '../styles/contact-form.css';

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simulate sending by setting a timeout
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 1);

    // Actual form submission logic can be added here if needed
  };

  return (
    <div>
      <form
        action="https://postmail.invotes.com/send"
        method="post"
        id="email_form"

      >
        <Input
          className="contact__form d-inline-block mb-4 me-4"
          type="text"
          name="extra_first_name"
          placeholder="First Name"
          required
        />

        <Input
          className="contact__form d-inline-block mb-4 ms-1"
          type="text"
          name="extra_last_name"
          placeholder="Last Name"
          required
        />
        <Input
          className="contact__form d-inline-block mb-4 me-4"
          type="text"
          name="subject"
          placeholder="Subject"
          required
        />

        <Input
          className="contact__form d-inline-block mb-4 ms-1"
          type="text"
          name="reply_to"
          placeholder="Your Email"
          required
        />
        <FormGroup className="contact__form w-100 ">

          <textarea
            rows="5"
            className="w-100 p-3"
            name="text"
            placeholder="Write your message here"
            required
          ></textarea>
        </FormGroup>

        <input
          type="hidden"
          name="access_token"
          value="ugwpj9cdb2awoedbdo8g0u2b"
        />
        <input
          type="hidden"
          name="success_url"
          value=".?message=Email+Successfully+Sent%21&isError=0"
        />
        <input
          type="hidden"
          name="error_url"
          value=".?message=Email+could+not+be+sent.&isError=1"
        />
        <input
          className="btn mt-4"
          id="submit_form"
          type="submit"
          value={isSubmitting ? 'Sending...' : 'Send Message'}
          disabled={isSubmitting}
        />
      </form>
    </div>
  );
};

export default ContactForm;
