import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/Etiquette.css';

const Etiquette = () => {
  return (
    <div className='ebody'>
    <hr className='ehr'></hr>
    <h2 className='eh2'>Etiquette</h2>
    <br></br>
    <ul className='eul'>
      <li>
      🐶 No pets—leave your furry friend at home. Fine: $125
      </li>
      <li>
      🚭Smoking fine is $175. Cleaning fee is $175 (spills, sand, pet hair etc.).
      </li>
    </ul>
    <br></br>
    <hr className='ehr'></hr>
    </div>
  );
};

export default Etiquette;
