import React from 'react';

import '../styles/footer.css';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const QUICK__LINKS = [
  {
    display: 'About Us',
    path: '#about_us',
  },

  {
    display: 'Car Listings',
    path: '#car_lisitngs',
  },

  {
    display: 'Testimonials',
    path: '#testimonial',
  },

  {
    display: 'Contact Us',
    path: '#contact_us',
  },
];

const Footer = () => {
  return (
    <footer>
      <Container>
        <div className="footer__top pb-5">
          <Row>
            <Col lg="4" md="4" sm="12">
              <div className="logo footer__logo__container mb-4">
                <h1>
                  <Link to="/home" className="footer__logo">
                    <span className="text-light ">
                      Atlanta Peach <br /> Rentals LLC
                    </span>
                  </Link>
                </h1>
                <p className="footer__logo-content">
                  At Atlanta Peach Rentals LLC, we're dedicated to providing top-quality car rental services in Atlanta. With a strong emphasis on customer satisfaction, we offer well-maintained vehicles, competitive prices, and personalized experiences to make your car rental process easy and enjoyable.
                </p>
              </div>
            </Col>
            <Col lg="2" md="4" sm="6">
              <div className="mb-4 ">
                <h5 className="text-light footer__quick__links">Quick Links</h5>
                <ListGroup>
                  {QUICK__LINKS.map((item, index) => (
                    <ListGroupItem className="p-0 mt-3 quick__links" key={index}>
                      <a href={item.path}>{item.display}</a>
                    </ListGroupItem>
                  ))}
                </ListGroup>

              </div>
            </Col>
            <Col lg="3" md="4" sm="6">
              <div className="mb-4">
                {' '}
                <h5 className="text-light mb-4 footer__quick__links">
                  Head Office
                </h5>
                <p className="head__office">
                  Atlanta, GA, USA.
                </p>
                <p className="head__office">Phone: +1 (770) 315-0006</p>
                <p className="head__office">Email: atlantapeachrentals@yahoo.com</p>
                <p className="head__office">Office Time: 10am - 7pm</p>
              </div>
            </Col>

            <Col lg="3" md="4">
              <div
                className="mb-4
              "
              >
                <h5 className="text-light mb-4 footer__quick__links">
                  Newsletter
                </h5>
                <p className="section__description">
                  Subscribe our newsletter to get updated information
                </p>
                <div className="newsletter">
                  <input type="email" placeholder="email address" />
                  <span>
                    <i className="ri-send-plane-line"></i>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Container>
          <Row>
            <Col>
              <div className="footer__bottom">
                <p className="section__description pt-4 fw-bold text-center d-flex align-items-center justify-content-center gap-1">
                  <i className="ri-copyright-line"></i> 2023 Atlanta Peach Rentals, developed by
                  <span>IdooTech</span>
                </p>
                <p className="section__description  fw-bold text-center d-flex align-items-center justify-content-center gap-1">
                  All Rights Reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
};

export default Footer;
