import React from 'react';
import Helmet from '../components/Helmet';
import { Container, Row, Col } from 'reactstrap';
import CommonSection from '../components/CommonSection';
import AboutSection from '../components/AboutSection';
import BecomeDriver from '../components/BecomeDriver';
import OurMember from '../components/OurMember';

import '../styles/about.css';

const About = () => {
  return (
    <Helmet title="About">
      <section className="pt-0">
        <CommonSection title="About Us" />
        <Container>
          <AboutSection />
        </Container>
      </section>

     
      <section className="become__driver">
        <Container>
          <BecomeDriver />
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
            <OurMember />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
