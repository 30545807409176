import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Helmet from '../components/Helmet';
import HeroSlider from '../components/HeroSlider';
import { Container, Row, Col } from 'reactstrap';
import FindCarForm from '../components/FindCarForm';

import HotOffer from '../components/HotOffer';
import Testimonial from '../components/Testimonial';

import ServicesItem from '../components/ServicesItem';
import BlogList from '../components/BlogList';

import BecomeDriver from '../components/BecomeDriver';
import Etiquette from '../components/Etiquette';


import AboutSection from '../components/AboutSection';

import Contact from './Contact';


const Home = () => {
  return (
    <Helmet title="Home">
      <section className="p-0 hero__slider__section">
        <HeroSlider />


      </section>

      <section className="about__section" id="about_us">
        <Container>
          <AboutSection />
        </Container>
      </section>


      <section id="car_lisitngs">
        <Container>
          <Row>
            <Col lg="12 text-center mb-5" >
              <h6 className="section__subtitle">Come With</h6>
              <h2 className="section__title">Hot Offers</h2>
            </Col>

            <HotOffer />
          </Row>
        </Container>
      </section>
      
      <section>
        <Container>
          < Etiquette />
        </Container>
      </section>

      <section className="become__driver">
        <Container>
          <BecomeDriver />
        </Container>
      </section>

      <section id="testimonial">
        <Container>
          <Row>
            <Col lg="12" className="text-center" >
              <h6 className="section__subtitle">Our Clients Says</h6>
              <h2 className="section__title">Testimonial</h2>
            </Col>

            <Testimonial />
          </Row>
        </Container>
      </section>

      <section id="contact_us">
        <Container>
          <Contact />
        </Container>
      </section>


    </Helmet>
  );
};

export default Home;
