import React from 'react';
import { Row, Col } from 'reactstrap';
import '../styles/about-section.css';
import driveImg from '../assets/img/drive.jpg';
import driveImg2 from '../assets/img/drive.png';


const AboutSection = () => {
  return (
    <Row>
      <Col lg="6" md="6">
        <div className="about__section__content">
          <h6 className="section__subtitle">About Us</h6>
          <h2 className="section__title">Welcome to Car Rent Service</h2>
          <p className="section__description">
            At Atlanta Peach Rentals LLC, we are your trusted partner for exceptional car rental services in the Atlanta area. With a commitment to excellence, we provide a diverse range of reliable and well-maintained vehicles that cater to the varied needs and preferences of our clients. Our goal is to exceed expectations by offering a wide selection of quality economy vehicles at competitive prices, ensuring you find the perfect vehicle for your journey.{' '}
          </p>
          <p className="section__description">
            What truly sets us apart is our unwavering dedication to customer service. Our friendly and knowledgeable team is always ready to assist you, ensuring every interaction is professional and personalized. We understand the importance of convenience in today's fast-paced world, which is why we offer flexible rental options, including short-term and long-term rentals, as well as convenient delivery and pick-up services, making the car rental process as effortless and efficient as possible.{' '}
          </p>

          <div className="about__section-checkbox d-flex align-items-center ">
            <p className="section__description d-flex align-items-center gap-2">
              <i className="ri-checkbox-circle-line"></i> Customer-Centric Service.
            </p>
            <p className="section__description d-flex align-items-center gap-2">
              <i className="ri-checkbox-circle-line"></i> Affordability.
            </p>
          </div>
          <div className="about__section-checkbox d-flex align-items-center  ">
            <p className="section__description d-flex align-items-center gap-2">
              <i className="ri-checkbox-circle-line"></i> Integrity & Transparency.
            </p>
            <p className="section__description d-flex align-items-center gap-2">
              <i className="ri-checkbox-circle-line"></i> Quality.
            </p>
          </div>
        </div>
      </Col>

      <Col lg="6" md="6">
        <div className="about__section-img">
          <img
            src={driveImg2}
            alt=""
            className="w-100"
          />
        </div>
      </Col>


      <Col lg="6" md="6" sm="12">
        <div className="about__section-img about__img">
          <img src={driveImg} alt="" className="w-100" />
        </div>
      </Col>
      <Col lg="6" md="6" sm="12">
        <div className="about__section__content">
          <h2 className="section__title about__title">
            We Are Committed To Provide Safe Ride Solutions
          </h2>
          <p className="section__description">
            At Atlanta Peach Rentals LLC, our mission is clear and resolute: to provide exceptional car rental services to our valued customers in the Atlanta area. We strive to deliver reliable, well-maintained vehicles that meet the diverse needs and preferences of our clients. Our aim is to exceed expectations by offering a comprehensive selection of quality economy vehicles at competitive prices.{' '}
          </p>
          <p className="section__description">
          Our mission is to provide exemplary customer service, fostering lasting client relationships marked by professionalism and personalization. We offer flexible rental options, including short-term and long-term rentals, along with convenient delivery and pick-up services. Our goal is to streamline the car rental experience, making it convenient and efficient. Choose Atlanta Peach Rentals LLC for an excellent, customized car rental experience in Atlanta.{' '}
          </p>

          <div className=" mt-4 d-flex align-items-center gap-3 ">
            <span className="fs-4">
              <i className="ri-phone-line"></i>
            </span>
            <div>
              <h6 className="section__subtitle">Need Any Help?</h6>
              <h4 className="about__phone">+1 (770) 315-0006</h4>
            </div>
          </div>
        </div>
      </Col>

    </Row>
  );
};

export default AboutSection;
