
import React from 'react';

import useFetch from '../hooks/useFetch';

import '../styles/hot-offer.css';
import CarCard from './CarCard';

import car1 from '../assets/img/car1c.jpeg'
import car2 from '../assets/img/car2c.jpg'

function getCarData() {
  return [
    {
      id: 1,
      brand: 'Hyundai',
      rating: 112,
      carName: 'Hyundai Accent',
      imgUrl: car1,
      link: 'https://www.getaround.com/BlueTint',
      model: 'Model-2019',
      price: 55,
      speed: 'Air conditioning',
      gps: 'GPS Navigation',
      seatType: 'Heated seats',
      automatic: 'Automatic',
      description:
        'The 2019 Hyundai Accent is a compact sedan that offers a sleek and stylish design. It has a refined appearance that is distinctive, giving this automobile an upscale look. The Accent\'s interior is very comfortable; the seats are supportive and provide ample legroom. The dashboard is thoughtfully designed. The 2019 Accent is paired with a 6-speed automatic transmission, providing smooth and responsive shifts. This is a great vehicle for those looking for a compact that is well-equipped and very affordable.',
    },
    {
      id: 2,
      brand: 'Toyota',
      rating: 102,
      carName: 'Toyota Yaris',
      imgUrl: car2,
      link: 'https://www.getaround.com/BlueTint',
      model: 'Model-2019',
      price: 50,
      speed: 'Air conditioning',
      gps: 'GPS Navigation',
      seatType: 'Heated seats',
      automatic: 'Automatic',
      description:
        'The 2019 Toyota Yaris is a stylish and modern design. It features a sleek exterior with clean lines and a sporty appearance. Inside, the Yaris offers a comfortable and well-designed cabin. The seats are supportive and provide ample legroom for both front and rear passengers. The dashboard is simple and intuitive with easy-to-use controls and a user-friendly entertainment system. The 2019 Yaris is powered by a 1.5-liter four-cylinder engine that delivers peppy acceleration and good fuel efficiency. Safety features include a rearview camera, forward collision warning, automatic emergency braking, and lane departure warning. The 2019 Yaris is a compact car that is both affordable and enjoyable to drive.',
    },
  ];
}



const HotOffer = () => {
  const carData = getCarData();
  return (
    <>
    
      <div className='car-flex'>
      {carData &&
              carData
                .slice(0, 2)
                .map((item, index) => <CarCard item={item} key={index} />)}
      </div>
      
    </>
  );
};
/*




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD2OwXlCLDqzTo64HJBbV5JhAoRbWl1IQc",
  authDomain: "atlanta-peach-rentals.firebaseapp.com",
  databaseURL: "https://atlanta-peach-rentals-default-rtdb.firebaseio.com",
  projectId: "atlanta-peach-rentals",
  storageBucket: "atlanta-peach-rentals.appspot.com",
  messagingSenderId: "61473326142",
  appId: "1:61473326142:web:3b0734a5a3bb0bacc5bd6f",
  measurementId: "G-XBMDHZJF5C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



const database = firebase.database();

// Reference to the 'users' node in the database
const usersRef = database.ref('cars');

const HotOffer = () => {

// Fetch data from the 'users' node
usersRef.once('value')
  .then(snapshot => {
    // The data is available in the snapshot object
    const { data: carData, isPending, error } = snapshot.val();
    console.log(data);

    return (
      <>
        {isPending && <h6 className="text-center">Loading......</h6>}
        {error && <h6 className="text-center">{error}</h6>}
        <div className='car-flex'>
        {carData &&
                carData
                  .slice(0, 2)
                  .map((item, index) => <CarCard item={item} key={index} />)}
        </div>
        
      </>
    );
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
}
*/

export default HotOffer;
