import React, { useRef } from 'react';


import { Container, Row, Col, Button } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import '../styles/header.css';
import { useDispatch, useSelector } from 'react-redux';
import { cartUiAction } from '../store/cartUi-Slice';
import logo from '../assets/img/logo512.png';


const NAV__MENU = [
  {
    text: 'Home',
    path: '/',
  },

  {
    text: 'About',
    path: '#about_us',
  },

  {
    text: 'Car Listing',
    path: '#car_lisitngs',
  },

  {
    text: 'Testimonials',
    path: '#testimonial',
  },

  {
    text: 'Contact',
    path: '#contact_us',
  },
];

const Header = () => {
  const menuRef = useRef();
  const dispatch = useDispatch();
  const totalQuantity = useSelector(state => state.cart.totalQuantity);

  const cartShowToggle = () => {
    dispatch(cartUiAction.toggle());
  };

  const menuToggle = () => menuRef.current.classList.toggle('menu__active');
  return (
    <header>


      <div className="main__header">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/home">
                    <img src={logo} className='logoImage'></img>{' '}
                    <span>
                      Atlanta Peach <br /> Rentals LLC
                    </span>
                  </Link>
                </h1>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location">
                <span>
                  <i className="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  <h4> USA</h4>
                  <h6>Atlanta, GA, USA</h6>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location">
                <span>
                  <i className="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Monday to Sunday</h4>
                  <h6>10am - 7pm</h6>
                </div>
              </div>
            </Col>

            <Col
              lg="2"
              md="3"
              sm="0"
              className="text-end d-flex align-items-center justify-content-end"
            >
              <Button className="header__btn btn">
                <a href="#contact_us">
                  <i className="ri-phone-line"></i> Get in touch
                </a>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="header__bottom">
        <Container>
          <div className="menu__container d-flex justify-content-between align-items-center">
            <span className="menu__bar">
              <i className="ri-menu-line" onClick={menuToggle}></i>
            </span>
            <div className="menu__list" ref={menuRef} onClick={menuToggle}>
              <div className="menu__left">
                {NAV__MENU.map((item, index) => (
                  <a
                    key={index}
                    href={item.path}
                    className={`me-4 ${window.location.pathname === item.path ? 'active' : ''}`}
                  >
                    {item.text}
                  </a>
                ))}



              </div>
            </div>

            <div className="menu__right d-flex gap-4 align-items-center  ">


              <div className="search">
                <input type="text" placeholder="search" />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
