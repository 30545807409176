import React from 'react';
import Slider from 'react-slick';

import ava1 from '../assets/img/t1.jpeg';
import ava2 from '../assets/img/t2.jpeg';
import ava3 from '../assets/img/t3.jpeg';
import '../styles/testimonial.css';
import ava4 from '../assets/img/ava-4.jpg';

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="mt-4">
      <div className="py-4 px-3 testimonial">
        <p className="section__description">
          I've Utilized numerous car rentals in the past.
          However, my experience renting from Atlanta Peach Rentals was awesome and worth sharing.
          Their customer service skills were exceptional! 
          <br></br>They were extremely accommodating and I look forward to contacting them again for my future rental car needs.
          Thank You! Atlanta Peach Rentals.
          {' '}
        </p>

        <div className="mt-3 d-flex align-items-center gap-4 ">
          <img src={ava1} alt="" className="w-25 h-25" />

          <div>
            <h6 className="mb-0 mt-3">Patricia</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>
      <div className="py-4 px-3 testimonial">
        <p className="section__description">
          I recently had the pleasure of renting a vehicle through Atlanta Peach Rentals, and I must say I was thoroughly impressed with their exceptional service and attention to detail.
          They genuinely cared about my comfort and satisfaction, going above and beyond to ensure I had a pleasant experience. I have no doubt that I will be choosing Atlanta Peach Rentals for my future rental needs.
          {' '}
        </p>

        <div className="mt-3 d-flex align-items-center gap-4 ">
          <img src={ava2} alt="" className="w-25 h-25" />

          <div>
            <h6 className="mb-0 mt-3">Mora</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>
      <div className="py-4 px-3 testimonial">
        <p className="section__description">
          I must say it was an excellent experience to rent from Atlanta Peach Rentals, The car was in pristine condition and provided a smooth and comfortable ride from point A to point B the 2019 Hyundai Accent was a delight to drive. It was fuel efficient, which was a great bonus for all the miles I had to drive. I highly recommend Atlanta Peach Rentals for anyone in need of a reliable and well-maintained rental car.
          {' '}
        </p>

        <div className="mt-3 d-flex align-items-center gap-4 ">
          <img src={ava3} alt="" className="w-25 h-25" />

          <div>
            <h6 className="mb-0 mt-3">Latresher</h6>
            <p className="section__description">Customer</p>
          </div>
        </div>
      </div>

    </Slider>
  );
};

export default Testimonial;
